<template>
  <div id="landing_container" ref="main" class="w-full bg-black h-screen overscroll-y-scroll overflow-x-hidden fixed top-0 left-0">
    <div class="sm:flex hidden sticky top-4 w-full justify-start items-start z-[100]">
      <NavGroup v-slot="{ ready, size, position, duration }" as="nav" class="relative mx-auto rounded-2xl bg-zinc-900/80 p-2 border border-white/20 backdrop-blur-sm">
        <div class="relative">
          <div
            v-if="ready"
            :style="{
              '--size': size,
              '--position': position,
              '--duration': duration,
            }"
            class="absolute inset-y-0 left-0 h-full w-[--size] translate-x-[--position] rounded-lg  transition-[width,transform] duration-[--duration]"
            :class="[position=='0px'?'bg-white/5':'bg-turquoise-500']"
          />

          <NavList as="ul" class="relative flex items-center gap-3">
            <NavItem ref="nav_hero" v-slot="{ setActive, isActive }" as="li" @click="navigate('Hero')">
              <a href="#" :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']" class="flex justify-center items-center p-2 text-sm transition w-14 " @click.prevent="setActive">
                <NuxtImg quality="50" class="w-12" src="/oxb_green_header.png" alt="Oxbull Logo" />
              </a>
            </NavItem>

            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Mission')" ref="nav_mission">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Mission
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Features')" ref="nav_features">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Features
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Projects')" ref="nav_projects">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Projects
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Tiers')" ref="nav_tiers">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Tiers
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Stake')" ref="nav_stake">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Stake
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Community')" ref="nav_community">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Community
              </a>
            </NavItem>
            <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate('Links')" ref="nav_links">
              <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                Links
              </a>
            </NavItem>
            <!-- <template v-for="(item, index) in items" :key="index">
              <NavItem v-slot="{ setActive, isActive }" as="li" @click="navigate(item)" :ref="`nav_${String(item).toLowerCase()}`">
                <a href="#"  :class="[ nuxtReady?( isActive ? 'text-white ' : 'text-white/60 hover:text-white'):'text-white/60']"  class="inline-block px-4 py-1 text-sm transition" @click.prevent="setActive">
                  {{ item }}
                </a>
              </NavItem>
            </template> -->
            
            <NavItem as="li">
              <a id="header_launch_app" href="/app" target="_blank" class="p-2 bg-white/5 text-turquoise-500 px-2 rounded-lg text-sm hover:bg-white/10 ">
                Launch DAPP
              </a>
            </NavItem>
          </NavList>
        </div>
      </NavGroup>
    </div>

 <div class="w-full sm:hidden flex sticky top-0 z-[100] p-2 ">
      <div class="w-full flex bg-white/5 p-2 border border-white/20 rounded-lg justify-between items-center backdrop-blur-xl">
        <a @click="navigate('hero')" href="" class="p-2">
          <NuxtImg quality="50" class="w-14" src="/oxb_green_header.png" alt="Oxbull Logo" />
        </a>

        <div>
          <Icon @click="open = !open" name="solar:hamburger-menu-outline" class="text-white text-3xl"></Icon>
          <HeadlessTransitionRoot as="template" :show="open">
            <HeadlessDialog as="div" class="relative z-[100]" @close="open = false">
              <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </HeadlessTransitionChild>

              <div class="fixed inset-0 z-10 w-screen overflow-y-auto pb-[50vh]">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 translate-x-4 scale-90" enter-to="opacity-100 translate-y-0 translate-x scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 translate-x scale-100" leave-to="opacity-0 translate-y-4 translate-x-4 scale-90">
                    <div class="fixed top-0 left-0 transform w-screen h-screen overflow-y-scroll bg-black p-2 pb-[50vh]">
                      <div class="w-full absolute top-0 h-[300px] bg-gradient-to-b from-turquoise-500/20 to-transparent left-0"></div>

                      <div class="w-full p-2 flex justify-between items-center sticky top-0 z-[101]">
                        <NuxtImg quality="50" class="w-14" src="/oxb_green_header.png" alt="Oxbull Logo" />
                        <button @click="open = !open" class="">
                          <Icon name="material-symbols-light:close" class="text-white text-3xl"></Icon>
                        </button>
                      </div>

                      <div class="w-full">
                        <div class="w-full flex justify-center items-center overflow-hidden relative">
                          <div class="w-full aspect-square flex justify-center items-center relative opacity-50">
                            <GoldBull class="z-20"></GoldBull>
                            <img class="w-[400px] h-[400px] absolute animate-spin-slow" src="/bull_text_circle.svg" />
                          </div>

                          <div class="w-24 h-24 rounded-md bg-white/20 backdrop-blur-sm absolute z-30 flex justify-center items-center">
                            <NuxtImg quality="50" class="w-16" src="/oxb_green_header.png" alt="Oxbull Logo" />
                          </div>

                          <div class="absolute z-30 mt-44">
                            <NuxtLink to="/app" id="header_launch_app" class="btn border-none text-lg h-auto bg-turquoise-500 px-4 py-3 rounded-full text-black font-brand font-bold">Launch Dapp</NuxtLink>
                          </div>
                        </div>
                      </div>

                      <div class="w-full">

               

                        <div class="w-full h-full">
                          <div class="w-full bg-white/5 rounded-md p-4 border border-white/20 backdrop-blur-sm">
                            <h6 class="text-white font-brand font-bold mb-1 px-2 text-lg">On This Page</h6>

                            <a href="#" v-for="item in items">
                              <button @click="navigate(item)" class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                {{ item }}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>

                      <section class="w-full flex flex-col justify-center items-center pt-2">
                        <div class="w-full flex flex-col justify-center items-center max-w-screen-xl">
                          <div class="w-full grid sm:grid-cols-3 grid-cols-1 gap-2">
                            <div class="w-full h-full">
                              <div class="w-full bg-white/5 rounded-md p-4 border border-white/20 backdrop-blur-sm">
                                <h6 class="text-white font-brand font-bold mb-1 px-2 text-lg">OXB Token</h6>

                                <button @click="copy()" class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-between items-center group">
                                  <span>Address: {{ OXB_V2_ADDRESS.substring(0, 10) }}...</span>

                                  <div class="flex justify-center items-center space-x-2">
                                    <template v-if="!copied">
                                      <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copy</span>
                                      <Icon name="mdi:content-copy" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                                    </template>

                                    <template v-else>
                                      <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copied</span>
                                      <Icon name="mdi:check" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                                    </template>
                                  </div>
                                </button>

                                <a id="header_buy_oxb" :href="`https://pancakeswap.finance/swap?outputCurrency=${OXB_V2_ADDRESS}`" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <img class="opacity-70 w-5 h-5 p-0.5 mr-2" src="/bunny-mono.png" alt="Pancakeswap logo" />
                                    Buy on PancakeSwap
                                  </button>
                                </a>

                                <a href="https://coinmarketcap.com/currencies/oxbull-tech/" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70" aria-hidden="true" />
                                    CoinMarketCap
                                  </button>
                                </a>

                                <a href="https://www.coingecko.com/en/coins/oxbull-tech" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    
                                    <img class="w-5 h-5 mr-2 opacity-70" src="/cg.svg" alt="CoinGecko logo" />
                                    CoinGecko
                                  </button>
                                </a>

                                <a id="header_launch_cryptorank" href="https://cryptorank.io/fundraising-platforms/oxbull" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                   
                                    <img class="w-5 h-5 mr-2 opacity-70" src="/symbol_white.png" alt="Cryptorank logo" />
                                    Cryptorank
                                  </button>
                                </a>

                                <a href="https://chainbroker.io/platforms/oxbull" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    
                                    <div class="w-5 h-5 mr-2 flex justify-center items-center">
                                      <img class="w-4 h-4 opacity-70" src="/chainbroker.png" alt="Chainbroker logo" />
                                    </div>
                                    Chainbroker
                                  </button>
                                </a>
                              </div>
                            </div>

                            <div class="w-full h-full">
                              <div class="w-full bg-white/5 rounded-md p-4 border border-white/20 backdrop-blur-sm">
                                <h6 class="text-white font-brand font-bold mb-1 px-2 text-lg">Socials</h6>

                                <a href="https://twitter.com/Oxbull5" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="prime:twitter" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    X @Oxbull5
                                  </button>
                                </a>

                                <a href="https://t.me/Oxbull_tech" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Official Group
                                  </button>
                                </a>

                                <a href="https://t.me/Oxbull_tech_announcement" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Announcement
                                  </button>
                                </a>

                                <a href="https://warpcast.com/oxbulltech" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="simple-icons:farcaster" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Farcaster @oxbulltech
                                  </button>
                                </a>

                                <a href="https://www.instagram.com/oxbulltech/" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="mdi:instagram" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Instagram @oxbulltech
                                  </button>
                                </a>

                                <a href="https://www.tiktok.com/@oxbull.tech" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="ic:baseline-tiktok" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Tiktok @oxbull.tech
                                  </button>
                                </a>
                              </div>
                            </div>

                            <div class="w-full h-full">
                              <div class="w-full bg-white/5 rounded-md p-4 border border-white/20 backdrop-blur-sm">
                                <h6 class="text-white font-brand font-bold mb-1 px-2 text-lg">Read More</h6>

                                <a href="https://oxbull.medium.com/" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Blog on Medium
                                  </button>
                                </a>

                                <a href="https://docs.oxbull.tech/" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    <Icon name="simple-icons:gitbook" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                                    Documentation
                                  </button>
                                </a>

                                <a href="https://oxbull.medium.com/introducing-oxbull-immunity-f6b9f9fe7453" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                   
                                    Oxbull Immunity
                                  </button>
                                </a>

                                <a href="/privacy" target="_blank" class="header_link" >
                                  <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                                    
                                    Privacy Policy
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </HeadlessTransitionChild>
                </div>
              </div>
            </HeadlessDialog>
          </HeadlessTransitionRoot>
        </div>


      </div>
    </div>

    <ScrollObserver @update="sectionUpdate">
      <ScrollTriggerGroup>
        <ScrollTrigger>
          <LandingHero id="section_hero"></LandingHero>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingMission id="section_mission"></LazyLandingMission>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingFeature id="section_features"></LazyLandingFeature>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingProjects id="section_projects"></LazyLandingProjects>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingTier v-if="nuxtReady" id="section_tiers"></LazyLandingTier>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingStake id="section_stake"></LazyLandingStake>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingCommunity id="section_community"></LazyLandingCommunity>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingApply></LazyLandingApply>
        </ScrollTrigger>
        <ScrollTrigger>
          <LazyLandingFooter id="section_links"></LazyLandingFooter>
        </ScrollTrigger>
      </ScrollTriggerGroup>
    </ScrollObserver>
  </div>
</template>

<script setup>
useHead({
  title: 'Oxbull | Home',
  meta: [
    { name: 'title', content: "Oxbull | Home" },
    { name: 'description', content: "Discover Oxbull.tech, a Startup Incubator and Launchpad driven by passionate Tech Enthusiasts. Our mission at Oxbull.Tech is to empower Startups with vital funding and support for a successful project launch. We specialize in connecting credible blockchain projects with Investors. Experience the Oxbull way - dedicated to Quality, Credibility, and Unwavering Execution. Join us for innovative projects that define the Oxbull.Tech commitment." }
  ],
  htmlAttrs: { lang: "en" }
})
definePageMeta({
  name: "home",
  middleware: ["landing"],
  layout: "landing"
});

// const tiers = ['Tier One', 'Tier Two', 'Tier Three']

// mingcute:safe-flash-fill

const main = ref(null);

const open = ref(false);

import NavGroup from "@/components/NavGroup.vue";
import NavItem from "@/components/NavItem.vue";
import NavList from "@/components/NavList.vue";

const items = ["Mission", "Features", "Projects", "Tiers", "Stake", "Community", "Links"];

const nav_hero = ref(null);
const nav_mission = ref(null);
const nav_features = ref(null);
const nav_projects = ref(null);
const nav_tiers = ref(null);
const nav_stake = ref(null);
const nav_community = ref(null);
const nav_links = ref(null);

const headersNav = [ nav_hero, nav_mission, nav_features, nav_projects, nav_tiers, nav_stake, nav_community, {} ,nav_links ]

import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
import { useClipboard } from "@vueuse/core";
import { debounce } from "lodash";
const { copy, copied } = useClipboard({ source: OXB_V2_ADDRESS });

let lenis;

const sectionUpdate = debounce((e) => {
  headersNav[ e ]?.value?.setActive( true )
  
  dataLayer.push({
    event: "scroll_to_landing_page_section",
    page: window.location.pathname,
    section_title: e === 0 
      ? 'Hero' 
      : e < 7
        ? items[ e-1 ]
        : e === 7
          ? 'Apply'
          : 'Links'
  })
}, 150);

function navigate(e) {
  if(open.value) open.value = false

  lenis.scrollTo(`#section_${e.toLowerCase()}`, {
    offset: -100,
    duration: 0.5
  });

  dataLayer.push({
    event: "click_landing_header_item",
    page: window.location.pathname,
    section_title: e
  });
  // the callback is fired once the animation is completed
  // to allow smooth transition
}

import Lenis from "@studio-freight/lenis";

onMounted(() => {
  lenis = new Lenis({
    wrapper: main.value,
    duration: 0.2
  });

  document.getElementById( 'landing_container' ).addEventListener( 'scroll', debounce(() => {
    if( lenis.dimensions.scrollHeight !== main.value.scrollHeight ){
      lenis = new Lenis({
        wrapper: main.value,
        duration: 0.2
      });
    }
  }, 150 ))

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
});

const nuxtReady = ref(false)

onNuxtReady(async () => {
  nuxtReady.value = true
})
</script>

<style>
html,
body {
  background: #000000 !important;
}
</style>
